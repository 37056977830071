import logo from './logo.svg';
import Terminal from "./components/Terminal";
import { TerminalContextProvider } from "react-terminal";
import  LandingPage  from "./components/page";



function App() {
 
  
  return (
    <div className="App">
      <header className="App-header">
        {/* <LandingPage /> */}
     
        <img src={logo} className="App-logo" alt="logo" />
        <div className="grid mt-5 ">
          <div className="col-12 md:col-12 lg:col-12">
            <div className="text-center">
              <TerminalContextProvider>
              <Terminal/>
            </TerminalContextProvider></div>
          </div>

        </div>
      </header>
    </div>
  );
}

export default App;
